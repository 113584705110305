@import './vars.scss';

html,
body {
  min-width: 1180px;
  @media (max-width: $tablet) {
    min-width: 0;
  }
  &.nav-opened {
    height: 100%;
    overflow: hidden;
  }
}

body {
  color: #000;
  font-family: 'PTSans-Caption', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  margin: 0; 
  font-size: 15px;
  position: relative;
  @media (min-width: $tablet) and (max-width: $desctop-sm) {
    zoom: 0.78;
  } 

}

.wrapper {
  position: relative;
  transition: all ease .3s;
  left: 0;
  z-index: 6;
  @media (max-width: $tablet) {
    box-shadow: 0 0 10px rgba(0, 14, 48, 0.2);
  }
  .nav-opened & {
    left: 260px;
    @media (max-width: $mobile) {
      left: 230px;
    }
  }
}

span.br {
  display: block;
  @media (max-width: $tablet) {
    display: inline;
  }
}

.limiter {
  width: 1162px;
  margin: 0 auto;
  @media (max-width: $tablet) {
    width: 687px;
  }
  @media (max-width: $mobile) {
    width: auto;
    padding: 0 10px;
  }
}

h2 {
  font-size: 42px;
  margin-top: 0;
  line-height: 1.2;
  .root & {
    text-align: center;
  }
  @media (max-width: $tablet) {
    font-size: 30px;
  }
  @media (max-width: $mobile) {
    font-size: 25px;
  }
}

h3 {
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 15px;
  line-height: 1.2;
  @media (max-width: $tablet) {
    font-size: 25px;
  }
  @media (max-width: $mobile) {
    font-size: 21px;
    margin-bottom: 10px;
  }
}

input {
  outline: none;
  &[type=submit] {
    -webkit-appearance: none;
  }
}

label.error {
  display: block;
  margin-top: 15px;
  color: red;
  text-align: center;
  font-size: 11px;
}

.gray {
  background-color: $gray-bg;
  padding-top: 170px;
  margin-top: 170px;
  @media (max-width: $tablet) {
    padding-top: 90px;
    margin-top: 90px;
  }
  section {
    &:first-child {
      margin-top: -340px;
      @media (max-width: $tablet) {
        margin-top: -180px;
      }
    }
  }
}

.rub {
  display: inline-block;
  position: relative;
  line-height: 0.73;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 76%;
    background-color: #000;
    top: 69%;
    height: 10%;
    left: 8%;
  }
}

.spb {
  display: none;
}

.cls-2 {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 13px;
  stroke: $dark-gray
}

#mainarea {
  position: relative;
  overflow: hidden;
}