@import './vars.scss';
.tariffs {
  display: none;
  h3 {
    font-weight: normal;
  }
  .special {
    background-image: url(../i/tarrif-banner.jpg);
    @media (max-width: $mobile) {
      background: url(../i/tarrif-banner-mobile.jpg);
      background-size: cover;
    }
  }
  &__title {
    margin-bottom: 60px;
    @media (max-width: $mobile) {
      margin-bottom: 30px;
    }
  }
  &__row {
    &:after {
      content: '';
      clear: both;
      display: table;
    }
  }
  &__price {
    margin-top: 75px;
    margin-bottom: 60px;
    @media (max-width: $mobile) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    &-text {
      color: $gray;
      font-size: 13px;
    }
    &-title {
      margin-bottom: 25px;
      @media (max-width: $mobile) {
        margin-bottom: 10px;
      }
    }
    &-cat {
      border-bottom: solid 2px $border-garay;
      margin-bottom: 25px;
      &-title {
        padding: 25px 0;
        font-size: 18px;
        font-weight: bold;
        @media (max-width: $mobile) {
          padding: 10px 30px 10px 0; 
        }
      }
      &-item {
        border-top: solid 1px $border-garay;
        padding: 25px 0;
        @media (max-width: $mobile) {
          padding: 20px 0;
        }
        &:after{
          content: '';
          clear: both;
          display: table;
        }
      }
      &-name {
        float: left;
        a {
          color: $green;
        }
        @media (max-width: $mobile) {
          font-size: 13px;
          max-width: 220px;
        }
      }
      &-price {
        float: right;
        padding-right: 60px;
        width: 75px;
        @media (max-width: $mobile) {
          padding: 0;
          max-width: 45px;
          font-size: 13px;
        }
      }
    }
  }
  .order__wrapper {
    padding-top: 50px;
     @media (max-width: $mobile) {
      padding-top: 30px;
     }
    .order__title {
      margin-bottom: 55px;
      @media (max-width: $mobile) {
        margin-bottom: 30px;
      }
    }
  }
  &__archive { 
    margin-top: 75px;
    margin-bottom: 60px;
    @media (max-width: $mobile) {
      margin-top: 0;
      margin-bottom: 30px;
      border-top: solid 1px $border-garay;
      padding-top: 35px;
    }
    &__title {
      margin-bottom: 40px;
      @media (max-width: $mobile) {
        font-size: 21px;
        font-weight: normal;
        margin-bottom: 25px;
      }
    }
    &-item {
      position: relative;
      border-bottom: solid 1px $border-garay;
      padding: 25px 65px 25px 0;
      @media (max-width: $mobile) {
        padding-top: 0;
        padding-right: 0;
        padding-bottom: 15px;
        &-desc {
          font-size: 13px;
          margin-top: 5px;
        }
      }
      &:last-child {
        border: none;
      }
      &-pdf {
        position: absolute;
        top: 0;
        right: 0;
        color: $green;
        display: block;
        border: solid 1px $green;
        border-radius: 3px;
        font-size: 11px;
        padding: 4px 11px;
        text-decoration: none;
        @media (max-width: $mobile) {
          display: none;
        }
      }
      &-name {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
        position: relative;
        margin: 0 -65px 0 0;
        display: inline-block;
        padding-right: 65px;
        @media (max-width: $mobile) {
          font-size: 15px;
        }
        a {
          color: $black;
          text-decoration: none;
          &.tariffs__archive-item-pdf {
            color: $green;
            
          }
        }
      }
    }
  }
  &__hide {
    color: $gray;
    cursor: pointer;
    text-align: center;
    span {
      border-bottom: dashed 1px $gray;
    }
  }
  &__more {
    text-decoration: none;
    display: inline-block;
    color: $white;
    position: relative;
    &:after {
      content: "";
      display: inline-block;
      width: 7px;
      height: 7px;
      border-right: 1px solid $white;
      border-top: 1px solid $white;
      transform: rotate(45deg);
      margin-left: 3px;
      position: relative;
      bottom: 1px;
    }
  }
  
}







