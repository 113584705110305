@import './vars.scss';

.order {
  &__wrapper {
    padding: 70px 0 0;
    margin-bottom: 10px;
    @media (max-width: $mobile) {
      padding-top: 40px;
    }
    @media (max-width: $mobile) {
      margin-bottom: 10px; 
    }
  }
  &__title {
    text-align: center;
  }
  &__tabs {
    padding: 12px 60px;
    cursor: pointer;
    border-bottom: solid 1px $border-garay;
    @media (max-width: $tablet) {
      display: none !important;
    }
    &.order__row {
      &:first-child {
        margin-bottom: 0;
      }
    }
    &-item {
      text-align: center;
      line-height: 62px;
      font-size: 18px;
      font-weight: bold;
      &._active {
        color: $gray;
        position: relative;
        font-weight: normal;
        &:after {
          content: '';
          width: 16px;
          height: 16px;
          display: block;
          position: absolute;
          border-bottom: solid 1px $border-garay;
          border-left: solid 1px $border-garay;
          background-color: $white;
          bottom: -21px;
          transform: rotate(-45deg);
          left: 50%;
          margin-left: -12px;
          z-index: 2;
        }
      }
    }
    &-body {
      &.hidden {
        display: none;
      }
    }
  }
  &__form {
    border: solid 1px $border-garay;
    border-radius: 3px;
    @media (max-width: $mobile) {
      border: none;
    }
    &-top {
      padding: 60px;
      @media (max-width: $tablet) {
        padding: 40px 40px 10px;
      }
      @media (max-width: $mobile) {
        padding: 0 10px 0;
      }
    }
    &-bottom {
      border-top: solid 1px $border-garay;
      padding: 30px 60px;
      font-size: 13px;
       @media (max-width: $tablet) {
        padding: 20px 40px 0;
      }
      @media (max-width: $mobile) {
        padding: 0 10px 0;
        border: none;
      }
      .order__col {
        &:first-child {
          padding-right: 60px;
           @media (max-width: $tablet) {
            padding-right: 0;
            border: none;
           }
        }
        &:last-child {
          text-align: center;
          .recover-sim & {
            text-align: right;
            @media (max-width: $tablet) {
              text-align: center;
            }
          }
        }
      }
    }
  }
  &__row {
    margin-bottom: 30px;
    @media (max-width: $mobile) {
      margin-bottom: 25px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:after {
      content: '';
      clear: both;
      display: table;
    }
  }
  &__col {
    float: left;
    box-sizing: border-box;
    &-delivery {
      line-height: 62px;
      @media (max-width: $tablet) {
        line-height: 40px;
        &.order__col {
          margin-bottom: 20px;
          margin-left: 45px;
          @media (max-width: $mobile) {
            margin-bottom: 0;
            margin-left: 0; 
            border-top: solid 1px $border-garay !important;
            padding-left: 40px !important;
            line-height: 70px;
          }
        }
      }
    }
    &-bordered {
      border-right: solid 1px $border-garay;
    }
    &-50 {
      width: 514px;
      @media (max-width: $tablet) {
        width: 100%;
        margin-bottom: 30px;
        float: none;
        &-recover {
            width: 296px;
            margin: 0 7px 0 0;
            float: left;
            @media (max-width: $mobile) {
              width: 100%;
            }
            &:last-child {
              float: right;
              margin-right: 0;
            }
        }
        @media (max-width: $mobile) {
          margin-bottom: 25px;
        }
        &.order__col-submit {
          margin: 0 -40px;
          border-top: solid 1px $border-garay;
          width: auto;
          @media (max-width: $mobile) {
            margin: 0;
          }
          &:last-child {
            padding: 40px;
            @media (max-width: $mobile) {
              padding: 30px 0;
            }
          }
          
        }
      }
      &:last-child {
        padding-left: 6px;
        @media (max-width: $tablet) {
          padding: 0;
        }
      }
      &:first-child {
        padding-right: 6px;
        padding-left: 0;
        @media (max-width: $tablet) {
          padding: 0;
        }
      }
      
    }
    &-33 {
      width: 33.33%;
      &:first-child {
        width: 32.78%;
        margin-right: .55%;
        @media (max-width: $tablet) {
          width: 345px;
          float: none;
          border: none;
          margin-bottom: 30px;
        }
        @media (max-width: $mobile) {
          width: 100%;
          float: none;
        }
      }
      @media (max-width: $tablet) {
        &:not(.order__col-bordered) {
          width: 296px;
            &:last-child {
              float: right;
              @media (max-width: $mobile) {
                margin-top: 25px;
              }
            }
            @media (max-width: $mobile) {
              width: 100%;
              float: none;
            }
        }
      }
      .order__field {
        margin-left: 10px;
        @media (max-width: $tablet) {
          margin-left: 0;
        }
      }
    }
    &-25 {
      width: 252px;
      margin: 0 7px;
      @media (max-width: $tablet) {
        width: 296px;
        margin: 0 7px 0 0;
      }
      @media (max-width: $mobile) {
        width: 100%;
        margin: 0;
        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
      &:last-child {
        float: right;
        margin-right: 0;
      }
    }
    &-building {
      width: 147px;
      margin: 0 7px;
      @media (max-width: $tablet) {
        width: 173px;
        margin-left: 0;
      }
      @media (max-width: $mobile) {
        width: 73px;
      }
    }
    &-corpus {
      width: 199px;
      margin: 0 7px;
      @media (max-width: $tablet) {
        width: 234px;
        margin: 0 5px;
      }
      @media (max-width: $mobile) {
        width: 113px;
        margin: 0 3px;
      }
    }
    &-flat {
      width: 147px;
      float: right;
      @media (max-width: $tablet) {
        width: 173px;
      }
      @media (max-width: $mobile) {
        width: 73px;
      }
    }
  }
  &__checkbox {
    padding-left: 45px;
    position: relative;
    @media (max-width: $mobile) {
      padding-left: 40px;
    }
    .error {
      position: absolute;
      bottom: -5px;
      transform: translateY(100%);
      margin-top: 10px;
    }
    input {
      display: none;
      &:checked {
        & ~ .order__checkbox-label {
          span {
            background: $green1 center url(../i/checkbox.png) no-repeat;
          }
        }
        & ~ p {
          display: block;
        }
      }
    }
    &-label {
      display: block;
      cursor: pointer;
      padding-top: 5px;
      @media (max-width: $mobile) {
        padding-top: 4px;
      }
      a {
        color: $black;
        text-decoration: none;
        &:hover {
          color: $green;
        }
      }
      span {
        display: block;
        position: absolute;
        width: 29px;
        height: 29px;
        border-radius: 3px;
        background-color: $form-green;
        left: 0;
        top: 0;
        @media (max-width: $mobile) {
          width: 27px;
          height: 27px;
        }
      }
    }
    p {
      font-size: 13px;
      margin: 20px 0 0;
      display: none;
      @media (max-width: $mobile) {
        margin-top: 15px;
      }
    }
    &.personal__data {
      margin: 20px 0 0;
    }
    &_delivery {
      font-size: 15px;
      &-text {
        margin-top: 20px;
        font-size: 13px;
        text-align: left;
        @media (max-width: $mobile) {
          margin-top: 10px;
        }
        p {
          display: block;
          margin: 10px 0;
          @media (max-width: $tablet) {
            margin: 0;
          }
        }
      }
    }
  }
  &__price {
    padding-left: 50px;
    font-size: 18px;
  }
  &__field {
    padding: 12px 30px;
    box-sizing: border-box;
    background-color: $form-green;
    height: 64px;
    border-radius: 32px;
    position: relative;
    @media (max-width: $mobile) {
      height: 58px;
      border-radius: 29px;
      padding: 12px 20px;
    }
    &.opened {
      z-index: 20;
    }
    &-street {
      .order__select-selected {
        padding: 11px 0 0;
      }
      &:after {
        content: url(../i/order-arrow.png);
        margin-left: 6px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 25px;
      }
    }
    input {
      border: none;
      background-color: transparent;
      font-size: 15px;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
       @media (max-width: $mobile) {
        font-size: 13px;
       }
    }
  }
  &__select {
    .error {
      position: absolute;
      left: 50%;
      margin-top: 16px;
      white-space: nowrap;
      transform: translate(-50%);
    }
    input {
      display: none;
    }
    &-label {
      font-size: 13px;
      color: $gray;
      margin-bottom: 2px;
    }
    &-selected {
      color: $gray;
      cursor: pointer;
      position: relative;
      z-index: 10;
      @media (max-width: $mobile) {
        font-size: 13px;
      }
    }
    &-dropdown {
      display: none;
      background-color: $form-green;
      border-bottom-left-radius: 32px;
      border-bottom-right-radius: 32px;
      position: absolute;
      width: 100%;
      left: 0;
      top: 32px;
      box-sizing: border-box;
      padding: 42px 30px 10px;
      &-item {
        color: $gray;
        font-size: 13px;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
  }
  &__quantity,
  &__time {
    position: relative;
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    &-label {
      float: left;
      width: 210px;
      padding-top: 13px;
      @media (max-width: $mobile) {
        width: 140px;
        padding-top: 0;
      }
      span {
        display: block;
        font-size: 13px;
        color: $gray;
        margin-top: 3px;
      }
    }
    &-select {
      width: 100px;
      float: right;
      margin-right: 15px;
      @media (max-width: $mobile) {
        margin-right: 0;
        width: 140px;
      }
      &:before {
        content: url(../i/order-arrow.png);
        margin-left: 6px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 25px;
        z-index: 10;
      }
    }
    .order__select-selected {
      line-height: 40px;
      @media (max-width: $tablet) {
        line-height: 34px;
      }

    }
  }
  &__city {
    position: relative;
    &-label {
      position: absolute;
      z-index: 21;
      font-size: 13px;
      color: $gray;
      padding: 12px 30px 0;
      @media (max-width: $mobile) {
        padding: 12px 20px 0;
      }
    }
    .order__select {
      margin-top: 20px;
      &-selected {
        color: $black;
      }
    }
  }
  &__field-region,
  &__time,
  &__date {
    &:before {
      content: url(../i/order-arrow.png);
      margin-left: 6px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 25px;
      z-index: 10;
    }
  }
  &__date {
    input {
      background: left 12px url(../i/calend.png) no-repeat;
      padding-left: 26px;
      color: $gray;
    }
  }
  &__summ {
    display: block;
    font-size: 25px;
    vertical-align: middle;
    font-weight: bold;
    margin: 0 auto 25px;
    text-align: center;
    @media (max-width: $tablet) {
      margin-right: 0;
      display: block;
      margin-bottom: 25px;
    }
    .rub {
      &:after {
        width: 78%;
        top: 63%;
        height: 15%;
        left: 5%;
      }
    }
  }
  &__submit {
    line-height: 64px;
    border-radius: 32px;
    padding: 0 22px;
    display: block;
    width: 205px;
    color: $black;
    text-transform: uppercase;
    font-size: 12px;
    text-decoration: none;
    font-weight: 600;
    border: none;
    margin: 0 auto;
    background-color: $light-green;
    cursor: pointer;
    @media (max-width: $mobile) {
      height: 58px;
      border-radius: 29px;
      line-height: 58px;
      display: block;
      width: 100%;
      font-size: 13px;
    }
  }
  &__map {
    height: 394px;
    position: relative;
  }
  &__offices {
    padding: 30px 60px;
    &-item {
      padding: 20px 0;
      border-bottom: solid 1px $border-garay;
      &:last-child {
        border: none;
      }
      div {
        position: relative;
        &:before {
          position: absolute;
          left: 0;
          top: 1px;
        }
      }
      &:after {
        content: '';
        clear: both;
        display: table;
      }
      &-address {
        float: left;
        box-sizing: border-box;
        padding: 0 60px 0 20px;
        width: 535px;
        &:before {
          content: url(../i/point.png);
        }
      }
      &-metro {
        float: left;
        box-sizing: border-box;
        padding: 0 25px;
        width: 220px;
        &:before {
          content: url(../i/metro.png);
        }
      }
      &-time {
        float: right;
        padding: 0 45px 0 20px;
        width: 220px;
        &:before {
          content: url(../i/time.png);
        }
      }
    }
  }
}

.confirmation {
  &__form {
    form {
      text-align: center;
    }
    &-title {
      font-size: 21px;
      font-weight: 400;
    }
    &-submit {
      line-height: 64px;
      border-radius: 32px;
      padding: 0 22px;
      color: $black;
      text-transform: uppercase;
      font-size: 12px;
      text-decoration: none;
      font-weight: 600;
      border: none;
      background-color: $light-green;
      cursor: pointer;
    }
    &-field {
      padding: 12px 30px;
      box-sizing: border-box;
      background-color: $form-green;
      height: 64px;
      border-radius: 32px;
      position: relative;
      margin: 20px 0 25px 0;
      input {
        border: none;
        background-color: transparent;
        font-size: 15px;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.notification {
  &__title {
    text-align: center;
    font-size: 21px;
    font-weight: 400;
    margin: 0;
  }
}