@import './vars.scss';
.error {
  .header {
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  .footer {
    border: none;
  }
  &__body {
    min-height: 640px;
    background: $orange center url(../i/error.jpg) no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  &__title {
    font-size: 18px;
    line-height: 1.4;
  }
  &__code {
    font-size: 133px;
    font-weight: bold;
    line-height: 165px;
  }
  &__button {
    display: block;
    width: 250px;
    background-color: $white;
    font-size: 14px;
    color: $black;
    text-transform: uppercase;
    height: 64px;
    line-height: 64px;
    font-weight: bold;
    border-radius: 32px; 
    text-decoration: none;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    margin: 10px auto 0;
  }
}