@font-face {
  font-family: 'PTSans-Caption';
  src: url("../fonts/PTSans-Caption.eot");
  src: url("../fonts/PTSans-Caption.eot?#iefix") format("embedded-opentype"),
    url("../fonts/PTSans-Caption.woff2") format("woff2"),
    url("../fonts/PTSans-Caption.woff") format("woff"),
    url("../fonts/PTSans-Caption.ttf") format("truetype"),
    url('../fonts/PTSans-Caption.svg#PTSans-Caption') format('svg');
}


@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: 'PTSans-Caption';
  src: url("../fonts/PTSans-CaptionBold.eot");
  src: url("../fonts/PTSans-CaptionBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/PTSans-CaptionBold.woff2") format("woff2"),
    url("../fonts/PTSans-CaptionBold.woff") format("woff"),
    url("../fonts/PTSans-CaptionBold.ttf") format("truetype");
  }

@font-face {
  font-style: normal;
  font-weight: normal;
  font-family: 'rouble-webfont';
  src: url("../fonts/rouble-webfont.eot");
  src: url("../fonts/rouble-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/rouble-webfont.woff2") format("woff2"),
    url("../fonts/rouble-webfont.woff") format("woff"),
    url("../fonts/rouble-webfont.ttf") format("truetype");
}