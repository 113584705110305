@import './vars.scss';

.popup {
  display: none;
  border-radius: 2px;
  position: absolute;
  width: 760px;
  background-color: $white;
  padding: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  @media (max-width: $mobile) {
    width: 100%;
    padding: 20px;
    max-height: 100%;
    overflow: auto;
  }
  &__wrapper {
    display: none;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
  }
  &__close {
    width: 18px;
    height: 18px;
    background: center url(../i/popup-close.png) no-repeat;
    cursor: pointer;
    top: 15px;
    right: 15px;
    position: absolute;
  }
  &__body {
    font-size: 14px;
    p {
      margin: 0 0 23px;
       @media (max-width: $mobile) {
        font-size: 11px;
        margin-bottom: 10px;
       }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__confirmation-form {
    width: 360px;
  }
  &__notification-success {
    width: 360px;
  }
}