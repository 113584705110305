@import './vars.scss';
.ui-datepicker {
  border: 1px solid  $border-garay;
  border-radius: 3px;
  overflow: hidden;
  z-index: 100 !important;
  &-header {
    background-color:$form-green;
    padding: 8px;
    text-align: center;
  }
  &-title {
    display: inline-block;
  }
  &-month {
    display: inline-block;
    width: 90px;
  }
  &-calendar {
    width: 100%;
    background: $white;
    font-size: 15px;
    th {
      text-align: center;
      width: 30px;
      padding: 4px 0 2px;
      font-weight: 700;
    }
    td {
      text-align: center;
      cursor: pointer;
      &.ui-state-disabled {
        color: $gray;
        cursor: default;
        span {
          color: $lightgray-text;
        }
      }
      a, span {
        padding: 5px 0 5px;
        display: block;
        text-decoration: none;
        border-radius: 3px;
        color: $black;
        &.ui-state-active {
          background-color: $green1;
          color: $black;
        }
      }
    }
  }
  &-next,
  &-prev {
    cursor: pointer;
  }
  &-next {
    float: right;
  },
  &-prev {
    float: left;
  }
}